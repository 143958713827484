<template>
  <div>
    <h2 class="heading-secondary my-5" v-text="'Tareas Programadas'"></h2>
    <div
      class="mb-4 metrics__graph--shadow"
      id="tasksChart"
      ref="tasksChart"></div>
  </div>
</template>

<script>
import chartMultiColumnConfig from "@/constants/metrics/chartMultiColumnConfig";
import ApexCharts from "apexcharts";

export default {
  name: "ABCGraphics",
  data() {
    return {
      chartMultiColumnConfig,
    };
  },
  mounted() {
    this.$emit("updateData");
  },
  methods: {
    loadData(payload, onMounted = false) {
      this.getABCCharts(payload, onMounted);
    },
    getABCCharts(payload, onMounted = false) {
      this.$api.metrics.getDataABCTasks(payload).then((res) => {
        this.plothChart(res.data.abcs, onMounted);
      });
    },
    chartColumnGenerator(onMounted = false, chartRef, options, chartName) {
      if (!onMounted && this[chartName]) this[chartName].destroy();
      this[chartName] = new ApexCharts(chartRef, options);
      this[chartName].render();
    },
    plothChart(plotData, onMounted = false) {
      const chartConfig = Object.assign({}, this.chartMultiColumnConfig);
      const series = [
        {
          name: "Total",
          type: "column",
          data: plotData.map((x) => x.total),
        },
        {
          name: "Completadas",
          type: "column",
          data: plotData.map((x) => x.completed),
        },
        {
          name: "Pendientes",
          type: "column",
          data: plotData.map((x) => x.pending),
        },
        {
          name: "Completadas Fuera de Tiempo",
          type: "column",
          data: plotData.map((x) => x.completed_out_of_time),
        },
      ];
      const labels = plotData.map((x) => x.name);
      chartConfig.colors = ["#284bbf", "#2ecc71", "#f7b924", "#ff0a00"];
      chartConfig.series = series;
      chartConfig.labels = labels;
      chartConfig.tooltip.x = {
        formatter(x, dataOnMove) {
          const index = dataOnMove.dataPointIndex;
          const infoByUser = plotData[index];
          return `${infoByUser.name}`;
        },
      };
      chartConfig.xaxis.categories = plotData.map((x) => x.name);
      this.chartColumnGenerator(
        onMounted,
        this.$refs.tasksChart,
        chartConfig,
        "tasksChart"
      );
    },
  },
};
</script>
